.header {
  display: flex;
  padding: 100px 40px;
  background-color: #000000;
}
.header .media {
  width: 50%;
  /* background-image: url(../Images/IMG_2488-01.jpg);
  background-size: cover; */
  background-color: #000000;
  text-align: center;
  padding: 50px 0;
}
.header .media img {
  width: 60%;
  margin: auto;
  border-radius: 10px;
}
.header .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 120px;
  padding: 40px;
  background-color: #000000;
  color: #fff;
}
.header .menu {
  display: flex;
  gap: 20px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
}
.header .menu a {
  color: #fff;
}
.header .info .logo {
  font-size: 20px;
  font-weight: 500;
}
.header .info .logo span {
  color: #4e4feb;
  margin-right: 5px;
}
.header .headline {
  font-size: 48px;
  font-weight: 600;
  margin: 30px 0;
  margin-bottom: 0px;
}
.header .sub-headline {
  font-size: 60px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.15);
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}
.about {
  padding: 80px 80px;
}
.about .title {
  text-align: left;
}
.about .title h1 {
  font-size: 56px;
  color: rgba(0, 0, 0, 0.1);
}
.about .section-name {
  margin-top: -90px;
  color: #111;
}
.about .info {
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}
.about .link {
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: rgba(76, 76, 235, 0.2);
}
.about .link:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-color: #4e4feb;
}
.skills {
  background-color: #000000;
  padding: 80px 80px;
  color: #fff;
}
.skills .title {
  text-align: center;
}
.skills .title h1 {
  font-size: 56px;
  color: rgba(255, 255, 255, 0.2);
}
.skills .section-name {
  margin-top: -90px;
  color: rgba(255, 255, 255, 1);
}
.skills .card {
  padding: 20px;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
  text-transform: capitalize;
  width: 200px;
  justify-content: center;
  border-radius: 10px;
  transition: transform 0.4s;
  margin: auto;
}
.skills .cards {
  padding: 40px 0;
}
.skills .card:hover {
  background-color: rgba(255, 255, 255, 0.08);
  transform: scale(1.08);
}
.skills #cpanel:hover {
  color: #ff6c2c;
}
.skills #wordpress:hover {
  color: #00749c;
}
.skills #node:hover {
  color: #3c873a;
}
.skills #react:hover {
  color: #61dbfb;
}
.skills #mysql:hover {
  color: #f29111;
}
.skills #mongodb:hover {
  color: #4db33d;
}
.skills #css:hover {
  color: #264de4;
}
.skills #html:hover {
  color: #e34c26;
}
.skills #js:hover {
  color: #f0db4f;
}
.skills #canva:hover {
  color: #4285f4;
}
.skills #php:hover {
  color: #474a8a;
}
.skills #figma:hover {
  color: #ae4dff;
}
.work {
  padding: 80px;
}
.work .title {
  text-align: left;
}
.work .title h1 {
  font-size: 56px;
  color: rgba(0, 0, 0, 0.1);
}
.work .section-name {
  margin-top: -90px;
  color: #111;
}
.work .cards {
  display: flex;
  gap: 50px;
  padding: 50px 0;
}
.work .card {
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.8s;
}
/* .work .card:hover {
  border-radius: 16px;
  transform: scale(1.02);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
} */
.work .card .title {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}
.work .card .title span {
  font-weight: 600;
  margin-right: 5px;
}
.work .card .status-og {
  border: 1px solid #e34c26;
  color: #e34c26;
  font-size: 14px;
  border-radius: 2px;
  padding: 5px 20px;
  border-radius: 28px;
}
.work .card .status-cmp {
  border: 1px solid #4db33d;
  color: #4db33d;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 2px;
  border-radius: 28px;
}
.work .card .view-btn a {
  color: #111;
  font-size: 18px;
}
.work .card .view-btn a:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  color: #4285f4;
  text-decoration-color: #4285f4;
}
.footer {
  padding: 80px;
  background-color: #000000;
  color: #fff;
  padding-bottom: 40px;
}

.footer .title {
  font-size: 20px;
  font-weight: 500;
}
.footer .title span {
  font-size: 36px;
  display: block;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.footer .social {
  font-size: 36px;
  display: flex;
  gap: 20px;
}
.footer .social a {
  color: #fff;
}
.footer .copyright {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
}
.footer .copyright span {
  color: rgb(255, 0, 0, 0.5);
}

.hamburger {
  color: #fff;
  font-size: 22px;
}
.mobile-menu {
  height: 100vh;
  background-color: #000000;
}
.page-intro {
  background-color: #000000;
  padding: 80px;
  text-transform: uppercase;
  text-align: center;
}
.page-name {
  color: rgba(255, 255, 255, 0.2);
  font-size: 52px;
  font-weight: 700;
}
.page-title {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
  margin-top: -50px;
  margin-left: 20px;
}
.page-content {
  padding: 40px 80px;
  font-size: 18px;
}
.about-desc {
  line-height: 30px;
  text-align: justify;
}
.about-desc i {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.box-wrap-1 {
  display: flex;
  gap: 100px;
  margin: 40px 0;
}
.box-wrap-1 .exp {
  width: 50%;
}
.box-wrap-1 .exp .title {
  font-size: 26px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.box-wrap-1 .cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 300px;
  overflow-y: auto;
  padding: 20px;
  padding-left: 0;
}
.box-wrap-1 .card {
  display: flex;
  align-items: center;
}
.box-wrap-1 .card .num {
  font-size: 58px;
  font-weight: 600;
  width: 30px;
  margin-left: 10px;
  z-index: -1;
  font-family: "Poppins", sans-serif;
}
.box-wrap-1 .card .sub {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 5px;
  background-color: #fff;
  width: 90%;
}
.box-wrap-1 .card .sub .profile {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.box-wrap-1 .card .sub .place {
  font-size: 16px;
  font-weight: 400;
}
.box-wrap-1 .card .sub .meta {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.5);
}
.about-skills {
  padding: 80px;
}
.about-skills .section-title {
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.about-skills .section-desc {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  text-align: center;
  margin: 20px 0;
  line-height: 30px;
}
.skills-box {
  display: grid;
  width: 100%;
  margin: auto;
  gap: 1rem 0;
  grid-template-columns: repeat(3, 1fr);
}
.skills-box .card {
  padding: 20px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
  text-transform: capitalize;
  width: 200px;
  justify-content: center;
  border-radius: 10px;
  transition: transform 0.4s;
  margin: auto;
}
.skills-box .cards {
  padding: 40px 0;
}
.skills-box .card:hover {
  background-color: rgba(255, 255, 255, 0.08);
  transform: scale(1.08);
}
.skills-box #cpanel:hover {
  color: #ff6c2c;
}
.skills-box #wordpress:hover {
  color: #00749c;
}
.skills-box #node:hover {
  color: #3c873a;
}
.skills-box #react:hover {
  color: #61dbfb;
}
.skills-box #mysql:hover {
  color: #f29111;
}
.skills-box #mongodb:hover {
  color: #4db33d;
}
.skills-box #css:hover {
  color: #264de4;
}
.skills-box #html:hover {
  color: #e34c26;
}
.skills-box #js:hover {
  color: #f0db4f;
}
.skills-box #canva:hover {
  color: #4285f4;
}
.skills-box #php:hover {
  color: #474a8a;
}
.skills-box #figma:hover {
  color: #ae4dff;
}
.skb-t1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 40px 0;
}
.skb-t1::after {
  content: " >";
  color: #264de4;
}
.work-page .cards {
  display: flex;
  gap: 50px;
  padding: 50px 0;
}
.work-page .card {
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.8s;
}
/* .work-page .card:hover {
  border-radius: 16px;
  transform: scale(1.02);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
} */
.work-page .card .title {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}
.work-page .card .title span {
  font-weight: 600;
  margin-right: 5px;
}
.work-page .card .status-og {
  border: 1px solid #e34c26;
  color: #e34c26;
  font-size: 14px;
  border-radius: 2px;
  padding: 5px 20px;
  border-radius: 28px;
}
.work-page .card .status-cmp {
  border: 1px solid #4db33d;
  color: #4db33d;
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 2px;
  border-radius: 28px;
}
.work-page .card .view-btn a {
  color: #111;
  font-size: 18px;
}
.work-page .card .view-btn a:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  color: #4285f4;
  text-decoration-color: #4285f4;
}
.contact-form {
  width: 50%;
}
.contact-form label {
  display: block;
}
.contact-form input,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  resize: none;
  margin-bottom: 15px;
  border-radius: 2px;
}
.contact-form input:focus,
textarea:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.contact-form button {
  width: 100%;
  padding: 12px;
  border: none;
  resize: none;
  margin-bottom: 15px;
  background-color: #000000;
  color: #fff;
  font-size: 18px;
  border-radius: 2px;
}
.page-content .social {
  font-size: 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.page-content .social .title {
  font-weight: 600;
}
.page-content .social a {
  color: #000000;
  padding: 0 10px;
}
.connect {
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .footer {
    padding: 80px 40px;
  }
  .skills {
    padding: 80px 40px;
  }
  .work {
    padding: 80px 40px;
  }
  .about {
    padding: 80px 40px;
  }
}
@media (min-width: 768px) {
  .om {
    display: none;
  }
}
@media (max-width: 768px) {
  .od {
    display: none;
  }
  .header {
    flex-direction: column;
    padding: 50px 20px !important;
  }
  .header .media {
    width: 100%;
    padding: 0px !important;
  }
  .header .media img {
    width: 100%;
  }
  .header .info {
    width: 100%;
    height: fit-content;
    padding: 0px;
  }
  .header .info span {
    display: block;
  }
  .work .cards {
    flex-direction: column;
    gap: 20px;
  }
  .footer {
    padding: 60px 20px;
  }
  .skills {
    padding: 60px 20px;
  }
  .work {
    padding: 60px 20px;
  }
  .about {
    padding: 60px 20px;
  }
  .footer .title span {
    font-size: 26px;
  }
  .footer .flex-sb {
    flex-direction: column;
    gap: 20px;
  }
  .headline {
    font-size: 36px !important;
  }
  .navbar {
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
  }
  .page-intro {
    padding: 40px !important;
  }
  .page-name {
    font-size: 48px !important;
  }
  .page-title {
    font-size: 34px !important;
    margin-left: 0 !important;
  }
  .page-content {
    padding: 20px !important;
  }
  .box-wrap-1 {
    flex-direction: column;
    gap: 40px;
  }
  .box-wrap-1 .exp {
    width: 100% !important;
  }
  .box-wrap-1 .exp .title {
    text-align: center;
  }
  .box-wrap-1 .exp .profile {
    font-size: 18px;
  }
  .about-skills {
    padding: 0;
  }
  .skills-box {
    grid-template-columns: repeat(2, 1fr);
  }
  .skb-t1 {
    font-size: 20px;
  }
  .skills-box .card {
    width: 150px;
    font-size: 18px;
  }
  .work-page .cards {
    flex-direction: column;
  }
  .connect {
    flex-direction: column;
    gap: 40px;
  }
  .connect .social {
    width: 100%;
  }
  .connect .contact-form {
    width: 100%;
  }
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
