.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1; /* Place it above the sidebar */
  overflow: hidden;
}

/* Rest of your styles remain the same */

.sidebar {
  position: fixed;
  top: 0;
  left: -500px; /* Initially hide the sidebar off-screen */
  width: 250px;
  height: 100%;
  background-color: #000000;
  color: #fff;
  transition: left 0.3s ease-in-out; /* Add a smooth transition effect */
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
}

.sidebar.open {
  left: 0; /* Slide the sidebar in when it's open */
  overflow: hidden;
}

.toggle-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.sidebar-content {
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar-content .menu ul {
  margin: 0;
  padding: 0;
  margin-top: 56px;
}
.sidebar-content .menu ul li {
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.sidebar-content .menu ul li a {
  color: #fff;
  text-decoration: none;
}
/* Navbar.css */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  background-color: #000000;
  backdrop-filter: blur(10px);
  color: #111;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 40px;
  display: none;
}
.nav2 {
  justify-content: space-between;
  padding: 20px 80px;
  display: flex;
}
.navbar.fixed {
  top: 0;
  display: flex;
}

.navbar.not-fixed {
  position: relative;
  top: auto;
}
.navbar .menu-button {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
a {
  text-decoration: none;
  color: #fff;
}
.navbar .logo {
  font-family: "Sacramento", cursive;
  font-size: 30px;
  color: #fff;
}
.navbar .cta_button {
  background-color: rgba(88, 0, 255, 0.9);
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
.navbar .menu {
  display: flex;
  gap: 20px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  align-items: center;
}
.navbar .resume {
  background-color: #333;
  padding: 10px;
  width: 120px;
  border-radius: 28px;
  text-align: center;
}
