@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111;
}

.exp .cards::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.exp .cards::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.exp .cards::-webkit-scrollbar-thumb {
  background: #111;
}

button {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}
a {
  text-decoration: none;
}
.down-btn {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.07);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-name {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
#loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Sacramento", cursive;
}

#signature-loader {
  font-size: 48px;
  color: #333;
}
.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Adjust the duration and timing function as needed */
}

.fade-in.loaded {
  opacity: 1;
}

.scroll-to-top {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.07);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 200px 0;
  font-family: "Poppins", sans-serif;
  padding: 20px;
}
.s-font {
  font-family: "M PLUS 1 Code", monospace;
}
